<script setup>
import { Analytics } from "@vercel/analytics/vue";
</script>

<template>
  <Analytics />
  <PersonalLanding />
</template>

<script>
import PersonalLanding from "./components/PersonalLanding.vue";

export default {
  name: "App",
  components: {
    PersonalLanding,
  },
};
</script>
